/* rc-picker */
/* purgecss start ignore */
.rc-picker-input > input {
	@apply px-4;
	@apply py-3;
	@apply w-full;
	@apply border;
	@apply border-gray-600;
	@apply rounded-md;
	@apply outline-none;
}

.rc-picker-input > input:focus {
	@apply border-gray-700;
	@apply outline-none;
	@apply shadow-inner;
}

.rc-picker-dropdown {
	position: absolute;
	@apply shadow-lg;
	@apply bg-white;
	@apply rounded;
	@apply border;
	z-index: 10000;
	width: 340px;
}
.rc-picker-dropdown-hidden {
	display: none;
}
.rc-picker-panel-container {
	@apply p-3;
}
.rc-picker-header-view {
	@apply w-20;
}
.rc-picker-header,
.rc-picker-header-view {
	@apply flex;
	@apply justify-between;
	@apply items-center;
}
.rc-picker-content {
	@apply mt-2;
	@apply w-full;
}
.rc-picker-cell-inner {
	@apply text-center;
	@apply w-full;
	@apply rounded;
	@apply p-2;
}
.rc-picker-cell {
	@apply text-gray-300;
	@apply p-0;
	@apply cursor-pointer;
}
.rc-picker-cell-in-view {
	@apply text-gray-700;
}
.rc-picker-cell-inner:hover,
.rc-picker-cell-range-start > .rc-picker-cell-inner,
.rc-picker-cell-range-end > .rc-picker-cell-inner,
.rc-picker-cell-selected > .rc-picker-cell-inner {
	@apply bg-blue-400;
	@apply text-white;
}

.rc-picker-input {
	position: relative;
	display: inline-flex;
	width: 100%;
}
.rc-picker-clear {
	position: absolute;
	top: 0;
	right: 4px;
	cursor: pointer;
}
.rc-picker-rtl .rc-picker-clear {
	right: auto;
	left: 4px;
}
.rc-picker-clear-btn {
	font-size: 26pt;
	@apply mr-2;
}
.rc-picker-clear-btn::after {
	content: '×';
}

.rc-picker-cell-disabled {
	background-color: #efefef;
}

.rc-picker-cell-today > .rc-picker-cell-inner {
	@apply border;
	@apply rounded-md;
	@apply border-gray-700;
}

.rc-picker-year-btn,
.rc-picker-month-btn,
.rc-picker-header-super-prev-btn,
.rc-picker-header-prev-btn,
.rc-picker-header-super-next-btn,
.rc-picker-header-next-btn {
	@apply px-2;
	@apply cursor-pointer;
	@apply rounded-md;
}

.rc-picker-year-btn:hover,
.rc-picker-month-btn:hover,
.rc-picker-header-super-prev-btn:hover,
.rc-picker-header-prev-btn:hover,
.rc-picker-header-super-next-btn:hover,
.rc-picker-header-next-btn:hover {
	@apply bg-blue-400;
	@apply text-white;
}
/* purgecss end ignore */
