/* purgecss start ignore */
.rc-dialog {
	position: relative;
	width: fit-content;
	margin: 20px;
	@apply transition-all;
	@apply duration-200;
}
.rc-dialog-wrap {
	position: fixed;
	overflow: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1050;
	-webkit-overflow-scrolling: touch;
	outline: 0;
	@apply flex;
	@apply items-center;
	@apply justify-center;
}
.rc-dialog-content {
	position: relative;
	border-radius: 6px;
	background-clip: padding-box;
	outline: 0;
	max-height: calc(100vh - 60px);
}
.rc-dialog-header {
	padding: 15px;
	border-bottom: 1px solid #e5e5e5;
}
.rc-dialog-close {
	cursor: pointer;
	border: 0;
	background: transparent;
	font-size: 24pt;
	position: absolute;
	right: 20px;
	top: 12px;
	font-weight: 700;
	line-height: 1;
	color: #000;
	filter: alpha(opacity=70);
	opacity: 0.7;
	text-decoration: none;
	z-index: 100;
	@apply transition-all;
	@apply duration-300;
}
.rc-dialog-close-x:after {
	content: '×';
}
.rc-dialog-close:hover {
	opacity: 1;
	filter: alpha(opacity=100);
	text-decoration: none;
}
.rc-dialog-title {
	margin: 0;
	line-height: 1.42857143;
}
.rc-dialog-body {
	position: relative;
	padding: 15px;
	@apply rounded-md;
}
.rc-dialog-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
}
.rc-dialog-footer .btn + .btn {
	margin-left: 5px;
	margin-bottom: 0;
}
.rc-dialog-footer .btn-group .btn + .btn {
	margin-left: -1px;
}
.rc-dialog-footer .btn-block + .btn-block {
	margin-left: 0;
}
@media (min-width: 768px) {
	/* .rc-dialog {
		width: 600px;
		margin: 30px auto;
	} */
	/* .rc-dialog-content {
		@apply shadow-lg;
		@apply shadow-neutral-700;
	} */
}
.rc-dialog-slide-fade-enter,
.rc-dialog-slide-fade-appear {
	transform: translate(0, -25%);
}
.rc-dialog-slide-fade-enter,
.rc-dialog-slide-fade-appear,
.rc-dialog-slide-fade-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: ease-out;
	animation-play-state: paused;
}
.rc-dialog-slide-fade-enter.rc-dialog-slide-fade-enter-active,
.rc-dialog-slide-fade-appear.rc-dialog-slide-fade-appear-active {
	animation-name: rcDialogSlideFadeIn;
	animation-play-state: running;
}
.rc-dialog-slide-fade-leave.rc-dialog-slide-fade-leave-active {
	animation-name: rcDialogSlideFadeOut;
	animation-play-state: running;
}
@keyframes rcDialogSlideFadeIn {
	0% {
		transform: translate(0, -25%);
	}
	100% {
		transform: translate(0, 0);
	}
}
@keyframes rcDialogSlideFadeOut {
	0% {
		transform: translate(0, 0);
	}
	100% {
		transform: translate(0, -25%);
	}
}
.rc-dialog-mask {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-color: #373737;
	background-color: rgba(55, 55, 55, 0.6);
	height: 100%;
	filter: alpha(opacity=50);
	z-index: 1050;
	@apply transition-all;
	@apply duration-200;
}
.rc-dialog-mask-hidden {
	display: none;
}
.rc-dialog-fade-enter,
.rc-dialog-fade-appear {
	opacity: 0;
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
	animation-play-state: paused;
}
.rc-dialog-fade-leave {
	animation-duration: 0.3s;
	animation-fill-mode: both;
	animation-timing-function: cubic-bezier(0.55, 0, 0.55, 0.2);
	animation-play-state: paused;
}
.rc-dialog-fade-enter.rc-dialog-fade-enter-active,
.rc-dialog-fade-appear.rc-dialog-fade-appear-active {
	animation-name: rcDialogFadeIn;
	animation-play-state: running;
}
.rc-dialog-fade-leave.rc-dialog-fade-leave-active {
	animation-name: rcDialogFadeOut;
	animation-play-state: running;
}
@keyframes rcDialogFadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes rcDialogFadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
/* purgecss end ignore */
