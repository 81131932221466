/* purgecss start ignore */
.rc-input-number {
	display: inline-block;
	margin: 0;
	padding: 0;
	vertical-align: middle;
	@apply w-full;
	@apply border;
	@apply border-gray-600;
	@apply rounded-md;
}
.rc-input-number-focused {
	border-color: #1890ff;
	box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
.rc-input-number-out-of-range input {
	color: red;
}
.rc-input-number-handler {
	display: block;
	overflow: hidden;
	line-height: 16px;
	text-align: center;
	touch-action: none;
	@apply bg-white;
	@apply h-5;
}
.rc-input-number-handler-active {
	background: #ddd;
}
.rc-input-number-handler-up-inner,
.rc-input-number-handler-down-inner {
	color: #666666;
	-webkit-user-select: none;
	user-select: none;
}
.rc-input-number:hover {
	border-color: #1890ff;
}
.rc-input-number:hover .rc-input-number-handler-up,
.rc-input-number:hover .rc-input-number-handler-wrap {
	border-color: #1890ff;
}
.rc-input-number-disabled:hover {
	border-color: #d9d9d9;
}
.rc-input-number-disabled:hover .rc-input-number-handler-up,
.rc-input-number-disabled:hover .rc-input-number-handler-wrap {
	border-color: #d9d9d9;
}
.rc-input-number-input-wrap {
	height: 100%;
	overflow: hidden;
	@apply rounded-md;
}
.rc-input-number-input {
	width: 100%;
	@apply px-4;
	@apply py-3;
	color: #666666;
	border: 0;
	@apply rounded-tl-md;
	@apply rounded-bl-md;
	outline: 0;
	transition: all 0.3s ease;
	transition: all 0.3s;
	-moz-appearance: textfield;
}
.rc-input-number-handler-wrap {
	float: right;
	width: 20px;
	height: 100%;
	border-left: 1px solid #d9d9d9;
	transition: all 0.3s;
}
.rc-input-number-handler-up {
	padding-top: 1px;
	border-bottom: 1px solid #d9d9d9;
	transition: all 0.3s;
	@apply rounded-tr-md;
}
.rc-input-number-handler-up-inner:after {
	content: '+';
}
.rc-input-number-handler-down {
	transition: all 0.3s;
	@apply rounded-br-md;
}
.rc-input-number-handler-down-inner:after {
	content: '-';
}
.rc-input-number-handler-down-disabled,
.rc-input-number-handler-up-disabled {
	opacity: 0.3;
}
.rc-input-number-handler-down-disabled:hover,
.rc-input-number-handler-up-disabled:hover {
	color: #999;
	border-color: #d9d9d9;
}
.rc-input-number-disabled .rc-input-number-input {
	background-color: #f3f3f3;
	cursor: not-allowed;
	opacity: 0.72;
}
.rc-input-number-disabled .rc-input-number-handler {
	opacity: 0.3;
}
.rc-input-number-disabled .rc-input-number-handler:hover {
	color: #999;
	border-color: #d9d9d9;
}
/* purgecss end ignore */
