@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap');
html,
body {
	font-family: Arial, Helvetica, sans-serif;
}

.breadcrumb .breadcrumb-item {
	position: relative;
}
.breadcrumb .breadcrumb-item:first-child {
	margin-left: 0 !important;
}
.breadcrumb .breadcrumb-item:not(:last-child):after {
	content: '/';
}

/* purgecss start ignore */
p {
	margin: 0;
}

pre {
	padding: 10px;
	background-color: #eee;
	white-space: pre-wrap;
	@apply rounded-md;
}

:not(pre) > code {
	font-family: monospace;
	background-color: #eee;
	padding: 3px;
	@apply rounded-md;
}

.img-modal {
	max-height: 34rem;
}

blockquote {
	border-left: 2px solid #ddd;
	margin-left: 0;
	margin-right: 0;
	padding-left: 10px;
	color: #aaa;
	font-style: italic;
}

blockquote[dir='rtl'] {
	border-left: none;
	padding-left: 0;
	padding-right: 10px;
	border-right: 2px solid #ddd;
}

table {
	border-collapse: collapse;
}

td {
	padding: 10px;
	border: 2px solid #ddd;
}

iframe {
	width: 100%;
	border: 1px solid #eee;
}

[data-slate-editor] > * + * {
	margin-top: 1em;
}

.print {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.print-container {
	border: 50px solid transparent;
	border-image: url(/images/border1.png) 33% round;
}

@media print {
	@page {
		margin: 0.5mm;
		size: A4;
	}

	body {
		margin: 0;
	}
	.print-hide,
	.page-title,
	.app-nav {
		display: none;
	}
	.app-content {
		padding: 1cm 0;
	}

	table {
		box-shadow: none !important;
	}
}
/* purgecss end ignore */
